import * as React from "react";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { ImageEmpty } from "../../../util/Images";

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const TextContainer = styled.div`
    margin-left: 32px;
`;

const Heading = styled.div`
    font-weight: bold;
    margin-bottom: 16px;
`;

export const EmptyHint = () => (
    <Container data-id="empty_list_hint">
        <ImageEmpty />
        <TextContainer>
            <Heading>{t("screen.doctor.patients.empty_list.heading")}</Heading>
        </TextContainer>
    </Container>
);
