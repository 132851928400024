const english = {
  "add_doctor_dialog.confirmation_text": "I hereby confirm that my physician agrees to be contacted by Pharmgenetix.",
  "add_doctor_dialog.description": "PLEASE NOTE: As the PGx-Optimizer® software is a medical device, training of the physician on the correct handling is mandatory. The training will take about 30 minutes. Please discuss with your physician in advance if he/she would like to be trained. You can remove the supplemented physician at any time.",
  "add_doctor_dialog.download_informations_pdf": "Physician is not informed yet",
  "add_doctor_dialog.form.city_error": "Please add a city/ town",
  "add_doctor_dialog.form.firstname_error": "Please add first name",
  "add_doctor_dialog.form.lastname_error": "Please add surname",
  "add_doctor_dialog.form.mail_error": "Please enter a valid email address",
  "add_doctor_dialog.form.next.text": "Physician is interested in PGx-training",
  "add_doctor_dialog.form.phone_error": "Please add a valid phone number",
  "add_doctor_dialog.form.submit.text": "Submit ",
  "add_doctor_dialog.form_group.contact_data.text": "Contact data of the doctor",
  "add_doctor_dialog.form_group.data.text": "Data of the doctor",
  "add_doctor_dialog.headline": "Add a doctor",
  "add_doctor_dialog.label.city": "City/ Town",
  "add_doctor_dialog.label.contact_type": "Contact type",
  "add_doctor_dialog.label.email": "E-mail",
  "add_doctor_dialog.label.firstname": "First name",
  "add_doctor_dialog.label.lastname": "Surname",
  "add_doctor_dialog.label.phone": "Phone",
  "add_doctor_dialog.label.zip": "ZIP Code",
  "summary_dialog.description": "A printable summary will be created. Please enter the recipient and their medical specialty.",
  "summary_dialog.form.firstname_error": "Please add first name",
  "summary_dialog.form.lastname_error": "Please add surname",
  "summary_dialog.form.submit.text": "Generate Summary",
  "summary_dialog.headline": "Create summary",
  "summary_dialog.label.field": "Field",
  "summary_dialog.label.firstname": "First name",
  "summary_dialog.label.lastname": "Surname",
  "summary_dialog.generic_error": "Error while creating the summary report.",
  "summary_dialog.medication_mismatch_error": "The information shown is outdated. Please refresh the page in order to receive the most up to date information.",
  "common.enzyme.interaction_with": "Interaction with",
  "common.enzyme.weight": "Impact on the end-result",
  "common.enzyme.weight.high": "High",
  "common.enzyme.weight.low": "Low",
  "common.enzyme.weight.medium": "Medium",
  "common.error": "Error",
  "common.here": "Here",
  "common.plan.basic": "Basic",
  "common.plan.totalCare": "Total Care",
  "common.print": "Print",
  "common.print.close": "Close print preview",
  "common.yes": "Yes",
  "common.no": "No",
  "common.unexpectedError":"Oops! Something went wrong. Please try again later.",
  "confirm_delete_dialog.delete": "Delete",
  "confirm_delete_dialog.headline.text": "Do you really want to delete this news?",
  "confirm_resend_dialog.cancel.label": "Cancel",
  "confirm_resend_dialog.headline.text": "Are you sure you want to send the invitation?",
  "confirm_resend_dialog.resend_password.label": "Send invitation",
  "confirm_reset_dialog.headline.text": "Are you sure you want to reset the password?",
  "confirm_reset_dialog.label": "Reset password",
  "date.cancelLabel": "Cancel",
  "date.clearLabel": "Delete",
  "date.validation_error": "Please enter a valid date",
  "dialog.button_cancel": "Cancel",
  "dialog.button_confirm": "Confirm",
  "dialog.button_lock": "Lock",
  "dialog.button_next": "Next",
  "dialog.button_release": "Unlock",
  "dialog.button_save": "Save",
  "doctor.appUsageWarningDialog.button.text": "Read and understood",
  "doctor.appUsageWarningDialog.text": `
        Please note that the pharmacogenetic results, interaction analysis and dose recommendations are to be used only by health care professionals.<br><br>
        This information does not replace the experience of the treating physician or their knowledge of the patient when making the diagnosis or preparing the treatment plan, nor does it relieve the prescribing physician of their duty of care or responsibility to prescribe.<br><br>
        Every prescription requires a thorough and conscious choice according to patient’s medical history and current clinical picture, and a corresponding observation of the patient after administration of the medication.<br><br>
        The drug interaction calculated here refers only to the genetics of drug metabolism. It should be noted that there may be other interactions that are not considered here.<br><br>
        In addition, it is possible that the phenotype may be altered by rare DNA variants that are unknown or have not been functionally tested.`,
  "doctor.appUsageWarningDialog.title": "Instruction for use",
  "doctor.notReleasedReportInfo": "One report discussion is still pending.",
  "doctor.notReleasedReportsInfo": "There are still {patientsWithoutReleasedReport} report discussions pending.",
  "patient.replaceWarningDialog.approval.text": "Read and understood",
  "patient.replaceWarningDialog.text": "Please note that a final therapy decision can only be made by a physician. The results of a pharmacogenetic analysis do not describe the overall picture of the patient and are only applicable under consideration of the complete clinical picture (e.g. kidney function, age, weight, allergies, etc.).\nRefrain from arbitrarily changing the dose of your medications or discontinuing them. Consult a doctor before any change.",
  "patient.replaceWarningDialog.title": "Note",
  "dropdown.label.atcGroups": "ATC group",
  "dropzone.button.label_empty": "BROWSE FILES",
  "dropzone.button.label_selected": "Replace file",
  "error.addDoctor": "Could not add doctor",
  "error.addDoctor.emailAlreadyInUse": "Doctors ID already exists",
  "error.addPatient": "Could not add patient",
  "error.calculate": "Could not calculate interactions",
  "error.copy": "Copy to clipboard failed",
  "error.csv_export": "Failed to create CSV file",
  "error.downloadReport": "Could not download report",
  "error.editDoctor": "Could not edit doctor",
  "error.editPatient": "Could not edit patient",
  "error.emailAlreadyInUse": "Doctors ID already exists",
  "error.errorScreenMessage": "Please try again later. If the error persists, click {errorLink} to contact us.",
  "error.getStatus": "Could not load status",
  "error.loadDoctorsList": "Could not load doctors list",
  "error.loadMedication": "Could not load drugs",
  "error.loadPatient": "Could not load patient data",
  "error.loadPatientsList": "Could not load patients list",
  "error.loadSingleMedGroups": "Could not load drug groups",
  "error.loadSubstanceData": "Error loading the necessary substance data",
  "error.loadSubstances": "Could not load list of drugs",
  "error.optimize": "Error on optimizing the therapy",
  "error.optimizer_timeout": "The calculation was too complex and therefor was canceled. Please consider reducing the amount of selected substances or lock some of them.",
  "error.releaseReport": "Could not release report",
  "error.resendEmail": "E-Mail could not be sent",
  "error.resetPassword": "Could not reset password",
  "error.saveMedication": "Could not save selected drugs",
  "error.serviceUnavailable": "The service is unfortunately not available at the moment. Please try again later.",
  "gender_disclaimer": "",
  "hisoty.open_history_button.text": "Open version",
  "history.description": "All drug analyses are archived and versioned with a date stamp and can be viewed at any time.",
  "history.info.headline": "Validity of Analysis",
  "history.info.text": "The database is regularly updated to reflect the current state of research. Therefore it is possible that older calculations may result in different conclusions.",
  "history.open_history_button.text": "Open version",
  "history.title": "Previous Analyses",
  "language.de": "German",
  "language.en": "English",
  "language.english": "English",
  "language.german": "German",
  "language.it": "Italian",
  "language.italian": "Italian",
  "language.language": "Language",
  "loadingscreen.heading": "Calculating ...",
  "loadingscreen.info_text": "Checking for possible combinations",
  "navigation.help.contact": "Contact",
  "navigation.help.label": "HELP",
  "navigation.help.manual": "Manual",
  "navigation.help.privacy_policy": "Privacy policy",
  "navigation.help.site_notice": "Imprint",
  "navigation.logout.label": "Logout",
  "news.content": "Content",
  "news.date": "Date",
  "news.title": "Title",
  "news.patientEnabled": "For patients",
  "news.doctorEnabled": "For physicians",
  "news.published": "Published",
  "news.unpublished": "Unpublished",
  "news.unreadNewsDialog.button.text": "Read",
  "news.unreadNewsDialog.title": "News",
  "news_modal.create_news": "Create News",
  "news_modal.edit_news": "Edit news",
  "news_modal.form.error.content": "Add content",
  "news_modal.form.error.title": "Add title",
  "news_modal.form.label.content": "Content",
  "news_modal.form.label.title": "Title",
  "news_modal.form.label.patientEnabled": "Publish for patients",
  "news_modal.form.label.doctorEnabled": "Publish for physicians",
  "news_modal.publish": "Publish",
  "optimizer.legend.danger.text": "Consider alternative",
  "optimizer.legend.decrease.high.text": "High",
  "optimizer.legend.decrease.low.text": "Low",
  "optimizer.legend.decrease.medium.text": "Medium",
  "optimizer.legend.enzymeActivity.danger.text": "Critical variant or critical interaction",
  "optimizer.legend.enzymeActivity.decrease.high.text": "High",
  "optimizer.legend.enzymeActivity.decrease.low.text": "Low",
  "optimizer.legend.enzymeActivity.decrease.medium.text": "Medium",
  "optimizer.legend.enzymeActivity.heading": "Enzyme activity",
  "optimizer.legend.enzymeActivity.increase.high.text": "High",
  "optimizer.legend.enzymeActivity.increase.low.text": "Low",
  "optimizer.legend.enzymeActivity.increase.medium.text": "Medium",
  "optimizer.legend.enzymeActivity.okay.text": "Normal enzyme function",
  "optimizer.legend.enzymeActivity.poorMetabolizer.text": "Poor Metabolizer",
  "optimizer.legend.enzymeActivity.section.decrease.text": "Enzyme activity decreased:",
  "optimizer.legend.enzymeActivity.section.increase.text": "Enzyme activity increased:",
  "optimizer.legend.enzymeActivity.section.okay.text": "Normal:",
  "optimizer.legend.enzymeActivity.section.other.text": "Other:",
  "optimizer.legend.enzymeActivity.section.warnings.text": "Warning:",
  "optimizer.legend.enzymeActivity.ultraRapidMetabolizer.text": "Ultrarapid Metabolizer",
  "optimizer.legend.enzymeActivity.unknown.text": "Untested",
  "optimizer.legend.heading": "Recommendations",
  "optimizer.legend.heading.info": "The recommendations are based on the current state of knowledge and on the results of the analysis.",
  "optimizer.legend.increase.high.text": "High",
  "optimizer.legend.increase.low.text": "Low",
  "optimizer.legend.increase.medium.text": "Medium",
  "optimizer.legend.info": "If drugs do not appear in the database, their pharmacogenetic relevance is currently unknown, not sufficiently substantiated/reviewed or not available. \nThe database is regularly updated based on recommendations of the regulatory authorities and the international scientific PGx consortia, as well as peer-reviewed medical literature. ",
  "optimizer.legend.information.cpic": "CPIC Guidelines",
  "optimizer.legend.information.text": "More Information:",
  "optimizer.legend.notTested.badge.text": "Not CYP metabolised",
  "optimizer.legend.okay.text": "Dosage according to drug SmPC",
  "optimizer.legend.section.decrease.text": "Consider dosage reduction:",
  "optimizer.legend.section.increase.text": "Consider dosage increase:",
  "optimizer.legend.section.not_recommended.badge.text": "Drug not recommended",
  "optimizer.legend.section.okay.text": "Normal:",
  "optimizer.legend.section.other.text": "Other:",
  "optimizer.legend.section.warning.badge.text": "Increased risk of side effects related to transporter or linked proteins",
  "optimizer.legend.section.warnings.text": "Warning:",
  "optimizer.legend.unknown.badge.text": "Untested",
  "password_strength_checker.info_text_begin": "Add a ",
  "password_strength_checker.info_text_end": "to protect your password.",
  "password_strength_checker.lower_case_letter": "lower case letter",
  "password_strength_checker.medium_password": "Medium password",
  "password_strength_checker.password_too_short": "The password should contain at least eight characters.",
  "password_strength_checker.special_character": "special character",
  "password_strength_checker.strong_password": "Strong password",
  "password_strength_checker.upper_case_letter": "upper case letter",
  "password_strength_checker.weak_password": "Weak passwort",
  "screen.doctor.auto_optimize.heading": "Calculated drug replacements",
  "screen.doctor.auto_optimize.navigation.replace_automatically": "Replace automatically",
  "screen.doctor.auto_optimize.no_enzymes_available": "No enzymes available",
  "screen.doctor.auto_optimize.section.combination": "Option",
  "screen.doctor.auto_optimize.section.combination.current": "NOW",
  "screen.doctor.auto_optimize.section.combination.replacement": "REPLACEMENT",
  "screen.doctor.auto_optimize.section.combination.select": "SELECT",
  "screen.doctor.auto_optimize.section.combinations": "Options",
  "screen.doctor.auto_optimize.section.replacement_in_different_level": "Possible replacement options",
  "screen.doctor.auto_optimize.section.replacement_in_different_level.description": "ATC switch possible",
  "screen.doctor.auto_optimize.section.replacement_in_same_level": "Replacement option in same ATC level",
  "screen.doctor.auto_optimize.section.replacement_in_same_level.description": "in same ATC level",
  "screen.doctor.auto_optimize.show_less_information": "HIDE ENZYMES",
  "screen.doctor.auto_optimize.show_more_information": "SHOW ENZYMES",
  "screen.doctor.auto_optimize.text": "Choose one of the replacement options.",
  "screen.doctor.lock_report.dialog.confirmation_question": "Do you want to lock the report for <b>{name}, born {birthdate}</b> to the patient?",
  "screen.doctor.lock_report.dialog.title": "Lock report",
  "screen.doctor.manual_optimize.enzyme.cyp3a5.info": "Globally, the *3 mutation is dominant with 88.7% and thus the greatly reduced enzyme activity of CYP3A5 represents the 'normal' enzyme activity.",
  "screen.doctor.manual_optimize.interaction_preview.explanation": "Adapted interaction of all drugs with the substitute for {substance}",
  "screen.doctor.manual_optimize.interaction_preview.heading": "Preview of Drug-Drug Interaction",
  "screen.doctor.manual_optimize.interaction_preview.hide_legend": "HIDE LEGEND",
  "screen.doctor.manual_optimize.interaction_preview.show_legend": "SHOW LEGEND",
  "screen.doctor.manual_optimize.interaction_preview.show_less_information": "HIDE ENZYMES",
  "screen.doctor.manual_optimize.interaction_preview.show_more_information": "SHOW ENZYMES",
  "screen.doctor.manual_optimize.navigation.replace_manually": "Replace drug manually",
  "screen.doctor.manual_optimize.prodrug.title": "Prodrug",
  "screen.doctor.manual_optimize.prodrug.text": "Has already been included in the calculation.",
  "screen.doctor.manual_optimize.substance_picker.dropdown.option.substance_count.text": "Drugs",
  "screen.doctor.manual_optimize.substance_picker.heading": "Drug-Substitutes",
  "screen.doctor.manual_optimize.substance_picker.keep_substance.button.text": "Keep drug",
  "screen.doctor.manual_optimize.substance_picker.save_substance.button.text": "Adopt substitute drug",
  "screen.doctor.manual_optimize.substance_picker.select_substance.text": "Select a drug:",
  "screen.doctor.navigation.news": "News",
  "screen.doctor.navigation.patient_overview": "Patient overview",
  "screen.doctor.news.description": "Recent updates of the PGx-Optimizer.",
  "screen.doctor.optimize.actions.analysisHistory": "Archived Analyses",
  "screen.doctor.optimize.actions.reset": "RESET",
  "screen.doctor.optimize.actions.searchbar.placeholder": "Search for and add drug",
  "screen.doctor.optimize.heading": "Drug check",
  "screen.doctor.optimize.history_information.headline": "Version from{date}, created by {doctorName}",
  "screen.doctor.optimize.no_medication.heading": "Inform patient about tolerability of selected medications.",
  "screen.doctor.optimize.no_medication.to_medication_groups": "Drug groups",
  "screen.doctor.optimize.optimize_medicaction": "Optimize drugs",
  "screen.doctor.optimize.replace_automatically.replacement.heading": "Drugs to be prescribed",
  "screen.doctor.optimize.replace_automatically.replacement.save": "Save",
  "screen.doctor.optimize.replace_automatically.replacement.sub_heading": "These are the results of the adapted calculation. You can save it for later. ",
  "screen.doctor.optimize.sub_text": "Enter drugs you would like to analyse.",
  "screen.doctor.optimize.getSummary": "Print",
  "screen.doctor.optimize.timeout.warning": "Warning: This calculation could take a long time to finish. Please reduce the amount of selected drugs.",
  "screen.doctor.patients.empty_list.heading": "No patient information is available yet.",
  "screen.doctor.patients.empty_list.pharmgenetix_info": "About PharmGenetix",
  "screen.doctor.patients.empty_list.text": "As soon as the first blood sample has been sent in, you will find it in this overview.",
  "screen.doctor.patients.searchbar.placeholder": "Search patient",
  "screen.doctor.release_report.dialog.confirmation_question": "I hereby confirm that I have trained the patient <b>{name}, born {birthdate}</b>, in detail regarding the PGx Optimizer and have informed and educated the patient comprehensively about the associated risks. The health of the patient always has the highest priority, which is why access to the PGx Optimizer is not granted lightly. I am aware of all the risks associated with its use.",
  "screen.doctor.release_report.dialog.title": "Unlock report",
  "screen.doctor.replacement_notice.dialog.placeholder": "Reason for less optimal replacement combination",
  "screen.doctor.replacement_notice.dialog.submit_with_notice": "Add note",
  "screen.doctor.replacement_notice.dialog.submit_without_notice": "Continue without note",
  "screen.doctor.replacement_notice.dialog.text": "You have chosen a less optimal replacement combination. Would you like to add a comment explaining your choice?",
  "screen.doctor.replacement_notice.dialog.title": "Add note",
  "screen.doctor.select_group.dialog.dialog.form.multiple.group.validation_error": "Please choose at least one drug group",
  "screen.doctor.select_group.dialog.dialog.form.single.group.validation_error": "Please choose the drug group",
  "screen.doctor.select_group.dialog.multiple_groups.text": "Please choose one or more groups in which you want to replace the drug.",
  "screen.doctor.select_group.dialog.single_group.text": "Please choose the group in which you want to replace the drug.",
  "screen.forgot_password.description": "You will receive a link via e-mail to reset your password.",
  "screen.forgot_password.form.email.label": "E-Mail",
  "screen.forgot_password.form.email.validation_error": "Please enter a valid e-mail address",
  "screen.forgot_password.form.error": "E-Mail could not be sent",
  "screen.forgot_password.form.submit": "Send link",
  "screen.forgot_password.form.success": "E-Mail was sent successfully",
  "screen.forgot_password.title": "Forgot password",
  "screen.login.error_during_login": "An error occured while logging in.",
  "screen.login.form.email.label": "E-Mail",
  "screen.login.form.email.validation_error": "Please enter a valid email address",
  "screen.login.form.forgot_password": "FORGOT PASSWORD?",
  "screen.login.form.password.label": "Password",
  "screen.login.form.password.validation_error": "Please enter a password",
  "screen.login.form.submit": "Login",
  "screen.login.form.validation_error_email_and_password": "Please enter a valid email address and your password",
  "screen.login.invalid_password_or_email": "Login failed. Please check your e-mail address and password.",
  "screen.not_found.title": "The site could not be found.",
  "screen.patient.medication_check.heading": "Drug check",
  "screen.patient.medication_check.text": "Overview of tested drug for monotherapy.",
  "screen.patient.medication_groups.heading": "Drugs and drug groups",
  "screen.patient.medication_groups.print.name.label": "PATIENT NAME",
  "screen.patient.medication_groups.text": "Drugs as mono therapy without drug-drug interactions.",
  "screen.pgx_admin.add_edit_doctor.dialog.contact_details.text": "Contact data",
  "screen.pgx_admin.add_edit_doctor.dialog.form.email.helper_text": "With this E-mail address the user gets access to pharmgenetix.com.",
  "screen.pgx_admin.add_edit_doctor.dialog.form.email.label": "E-Mail",
  "screen.pgx_admin.add_edit_doctor.dialog.form.email.validation_error": "Please enter a valid E-mail address",
  "screen.pgx_admin.add_edit_doctor.dialog.form.firstname.label": "First name",
  "screen.pgx_admin.add_edit_doctor.dialog.form.firstname.validation_error": "Please enter a first name",
  "screen.pgx_admin.add_edit_doctor.dialog.form.language": "Default language",
  "screen.pgx_admin.add_edit_doctor.dialog.form.lastname.label": "Last name",
  "screen.pgx_admin.add_edit_doctor.dialog.form.lastname.validation_error": "Please enter a last name",
  "screen.pgx_admin.add_edit_doctor.dialog.form.pharmgenetix_id.label": "PharmGenetix doctors-ID (optional)",
  "screen.pgx_admin.add_edit_doctor.dialog.personal_details.text": "Personal data",
  "screen.pgx_admin.add_edit_doctor.dialog.title": "New doctor",
  "screen.pgx_admin.add_edit_patient.dialog.contact_details.text": "Contact data",
  "screen.pgx_admin.add_edit_patient.dialog.form.birthdate.label": "birth date (DD.MM.YYYY)",
  "screen.pgx_admin.add_edit_patient.dialog.form.birthdate.validation_empty_error": "Please enter a birth date",
  "screen.pgx_admin.add_edit_patient.dialog.form.doctor.helper_text": "In case the name of the doctor is not listed, add a the doctor in the section „Doctor management“.",
  "screen.pgx_admin.add_edit_patient.dialog.form.doctor.label": "Name of the doctor",
  "screen.pgx_admin.add_edit_patient.dialog.form.doctor.validation_error": "Please select a doctor",
  "screen.pgx_admin.add_edit_patient.dialog.form.email.helper_text": "As soon as the report is available, the patient has access via this address to pharmgenetix.com.",
  "screen.pgx_admin.add_edit_patient.dialog.form.email.label": "E-Mail",
  "screen.pgx_admin.add_edit_patient.dialog.form.email.validation_error": "Please enter a valid email address",
  "screen.pgx_admin.add_edit_patient.dialog.form.firstname.label": "First name",
  "screen.pgx_admin.add_edit_patient.dialog.form.firstname.validation_error": "Please enter a first name",
  "screen.pgx_admin.add_edit_patient.dialog.form.language": "Default language",
  "screen.pgx_admin.add_edit_patient.dialog.form.lastname.label": "Last name",
  "screen.pgx_admin.add_edit_patient.dialog.form.lastname.validation_error": "Please enter a last name",
  "screen.pgx_admin.add_edit_patient.dialog.form.pgx_file.label": "PGx file",
  "screen.pgx_admin.add_edit_patient.dialog.form.pgx_file.validation_empty_error": "Please provide a PGx file (.pgx-report)",
  "screen.pgx_admin.add_edit_patient.dialog.form.pharmgenetix_id.label": "PharmGenetix patient-ID",
  "screen.pgx_admin.add_edit_patient.dialog.form.pharmgenetix_id.validation_error": "Please enter a Patient-ID",
  "screen.pgx_admin.add_edit_patient.dialog.form.report_file.label": "Results as PDF",
  "screen.pgx_admin.add_edit_patient.dialog.form.report_file.validation_empty_error": "Please choose a PDF file",
  "screen.pgx_admin.add_edit_patient.dialog.personal_details.text": "Personal data",
  "screen.pgx_admin.add_edit_patient.dialog.responsible_doctor.text": "Responsible doctor",
  "screen.pgx_admin.add_edit_patient.dialog.title": "New patient",
  "screen.pgx_admin.confirm_dialog.description.doctor": "Do you want to delete this doctor from the list?",
  "screen.pgx_admin.confirm_dialog.description.link_to_patient_text": "Link to patient",
  "screen.pgx_admin.confirm_dialog.description.patient": "Do you want to delete this patient from the list?",
  "screen.pgx_admin.confirm_dialog.description.reassign_patients": "To delete this doctor, you have to link this patient to a new physician. ",
  "screen.pgx_admin.confirm_dialog.headline.doctor": "Delete doctor",
  "screen.pgx_admin.confirm_dialog.headline.patient": "Delete patient",
  "screen.pgx_admin.csv_export.button.label": "CSV EXPORT",
  "screen.pgx_admin.doctors.actions.add.button.label": "New doctor",
  "screen.pgx_admin.doctors.actions.searchbar.placeholder": "Search doctor",
  "screen.pgx_admin.doctors.empty_list.add.button.label": "Add first doctor",
  "screen.pgx_admin.doctors.empty_list.text": "You did not add a doctor yet.",
  "screen.pgx_admin.navigation.tab.doctor_management": "Listed Doctors",
  "screen.pgx_admin.navigation.tab.news": "News",
  "screen.pgx_admin.navigation.tab.patient_management": "Listed Patients",
  "screen.pgx_admin.news.add_news": "Create a new post",
  "screen.pgx_admin.patients.actions.add.button.label": "New patient",
  "screen.pgx_admin.patients.actions.searchbar.placeholder": "Search patient",
  "screen.pgx_admin.patients.empty_list.add.button.label": "Add first patient",
  "screen.pgx_admin.patients.empty_list.text": "You did not add a patient yet.",
  "screen.set_password.description": "Use a mix of special characters, upper and lower case letters as well as numbers to create a secure password. Your password must contain at least 8 characters.",
  "screen.set_password.form.error_expired_token": "The provided link has expired. Please request a new one.",
  "screen.set_password.form.error_invalid_token": "The provided link is invalid. Please request a new one.",
  "screen.set_password.form.error_weak_password": "The password you provided doesn't meet the requirements to be secure. Please choose another password.",
  "screen.set_password.form.password.confirm.label": "Confirm new password",
  "screen.set_password.form.password.confirm.required.validation_error": "Please confirm your password",
  "screen.set_password.form.password.confirm.validation_error": "Passwords do not match",
  "screen.set_password.form.password.label": "New password",
  "screen.set_password.form.password.validation_error": "Please enter a valid password",
  "screen.set_password.form.submit": "Set new password",
  "screen.set_password.form.success": "Your new password has been set successfully. You will be redirected to the login page automatically.",
  "screen.set_password.invalid_token.description": "The link provided is no longer valid. If you have already assigned the password, you can log in directly.",
  "screen.set_password.invalid_token.title": "Invalid Link",
  "screen.set_password.title": "Set password",
  "select.no_options": "No Options",
  "success.copy": "Copied to clipboard",
  "success.resendEmail": "E-Mail was sent successfully",
  "table.action.analyse": "ANALYZE",
  "table.action.copy.passwordResetLink": "Copy Password reset link",
  "table.action.copy.patient": "Copy patient link",
  "table.action.copy.title": "Copy link",
  "table.action.delete.doctor": "Delete doctor",
  "table.action.delete.patient": "Delete Patient",
  "table.action.delete.title": "Remove from list",
  "table.action.delete_doctor.text": "Delete doctor",
  "table.action.delete_patient.description.text": "Do you want to delete this patient?",
  "table.action.delete_patient.headline.text": "Delete patient?",
  "table.action.delete_patient.text": "Delete patient",
  "table.action.download_report.title": "Download report",
  "table.action.dropdown.download_report": "Download report",
  "table.action.dropdown.medication_check": "Medication check",
  "table.action.dropdown.medication_group": "Drug group",
  "table.action.edit.title": "Edit",
  "table.action.fix": "LOCK",
  "table.action.fixed": "LOCKED",
  "table.action.lock_report.title": "Lock report",
  "table.action.locked_report.title": "Report not yet released",
  "table.action.medication_check.title": "Drug check",
  "table.action.medication_groups.title": "Drug groups",
  "table.action.optimize": "OPTIMIZE",
  "table.action.release_report.title": "Unlock report",
  "table.action.reset_password.title": "Reset password",
  "table.action.send_invitation.title": "Send invitation",
  "table.heading.active_agent": "DRUG",
  "table.heading.birthdate": "BIRTHDATE",
  "table.heading.checksum": "Checkcode",
  "table.heading.doctor": "DOCTOR",
  "table.heading.email": "E-MAIL",
  "table.heading.firstname": "FIRST NAME",
  "table.heading.interaction": "INTERACTION",
  "table.heading.last_active_at": "LAST ACTIVE",
  "table.heading.lastname": "LAST NAME",
  "table.heading.pharmgenetix_id": "ID",
  "table.heading.plan": "PACKAGE",
  "table.heading.report": "REPORT",
  "table.heading.reports": "REPORTS",
  "table.heading.reports_in_process_and_total": "in Progress/total",
  "table.heading.selection": "SELECTION",
  "table.heading.single_medication": "MONOTHERAPY",
  "table.heading.single_medication_short": "MONOTHER.",
  "table.heading.training": "Training Completed",
  "texterify_timestamp": "2022-12-19T13:55:03Z",
  "mfa.form_header": "Enter verification code",
  "mfa.instruction": "You will soon receive a verification code via email. Please enter this code in the field below.",
  "mfa.code_label": "Verification code",
  "mfa.submit": "Send verification code",
  "mfa.code_error": "Field must not be empty",
  "mfa.button_label_enabled": "Two-factor authentication is currently enabled. Click to disable.",
  "mfa.button_label_disabled": "Two-factor authentication is currently disabled. Click to enable.",
  "mfa.remember_device_label": "Don't ask for verification code on this device for the next 90 days.",
  "mfa.triesLeftError": "Incorrect verification code. Attempts remaining: {tries}.",
  "mfa.authError": "Incorrect login credentials. Please check your input and try again.",
};

export { english };
