import { uniqBy } from "lodash";
import { action, observable } from "mobx";
import { getTestedGroups } from "../components/util/Patient";
import { t } from "../i18n/util";
import { API, isUnauthorizedError } from "../network/API";
import { IPatient, ISubstance, ISubstanceId } from "../types";
import { generalStore } from "./GeneralStore";
import { ISingleMedGroup } from "../types";

class PatientStore {
    @observable isRehydrated = false;
    @observable groups: ISingleMedGroup[] = [];
    @observable substanceCalculateResults: { [substanceId in ISubstanceId]: ISubstance } | null = null;
    @observable isLoading = false;

    @action wipe() {
        this.groups = [];
        this.substanceCalculateResults = null;
    }

    @action async loadGroups(patient: IPatient) {
        try {
            this.isLoading = true;

            const result = await API.getMedicationGroups(patient.uid);

            const resultSubstances = result.combinations[0].medication;

            const uniqSubstances = uniqBy(resultSubstances, substance => substance.id);
            const mappedSubstances = uniqSubstances.reduce<{ [substanceId in ISubstanceId]: ISubstance }>(
                (accumulator, substance) => ({
                    ...accumulator,
                    [substance.id]: substance,
                }),
                {},
            );

            this.substanceCalculateResults = mappedSubstances;

            const testedGroups = getTestedGroups(patient);

            const groupNames = Object.keys(testedGroups);

            const groupsWithSubstances = groupNames.map(groupName => ({
                name: groupName,
                substances: testedGroups[groupName].map(substanceId => mappedSubstances[substanceId]),
            }));

            this.groups = groupsWithSubstances;
        } catch (error) {
            this.groups = [];
            this.substanceCalculateResults = null;
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.loadSingleMedGroups");
            }
            console.error(error);
        }

        this.isLoading = false;
    }
}

const patientStore = new PatientStore();

export { patientStore };
