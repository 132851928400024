import { Button } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { ImageEmpty } from "../../../util/Images";
import { Routes } from "../../../routers/Routes";
import { doctorStore } from "../../../../stores/DoctorStore";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
`;

const Heading = styled.div`
    width: 332px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 32px;
`;

const StyledImageEmpty = styled(ImageEmpty)`
    margin-bottom: 16px;
`;

export const NoMedicationHint = observer(() => {
    return (
        <Container data-id="no_medication_hint">
            <StyledImageEmpty />
            <Heading>
                {t("screen.doctor.optimize.no_medication.heading")}
            </Heading>
            <Button
                data-id="to_medication_groups"
                variant="contained"
                color="primary"
                component={Link}
                to={Routes.DOCTOR.MEDICATION_GROUPS.replace(":uid", doctorStore.selectedPatient?.uid || "")}
                target="_blank"
                style={{
                    width: 317,
                }}
            >
                {t("screen.doctor.optimize.no_medication.to_medication_groups")}
            </Button>
        </Container>
    );
});
