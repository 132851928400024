import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { ISingleMedGroup } from "../../../../../../types";
import { t } from "../../../../../../i18n/util";
import { BadgeCollection } from "../../../../../ui/BadgeCollection";
import { Badge } from "../../../../../util/Badge";
import { Colors } from "../../../../../util/Colors";
import { Icon } from "../../../../../util/Icon";
import { getTranslation } from "../../../../../util/Optimizer";
import { orderBy, uniqBy } from "lodash";
import { ISubstance } from "../../../../../../types";

const PrintIcon = ({ onClick }: { onClick?: (group: ISingleMedGroup) => void }) => (
    <div
        style={{
            borderRadius: "100%",
            boxShadow: "0px 0px 9px 0px rgba(0,0,0,0.1)",
            display: "flex",
            padding: 4,
            alignItems: "center",
        }}
    >
        <Icon name={"print"} color={Colors.primary} hoverColor={Colors.secondary} size={32} onClick={onClick} />
    </div>
);

type ISortColumn = "name" | "fc";

type IProps = {
    // group: IAtcGroup;
    group: ISingleMedGroup;
    open: boolean;
    onToggleOpen?: (group: ISingleMedGroup) => void;
    onClickPrint?: (group: ISingleMedGroup) => void;
    showButtons?: boolean;
    "data-id"?: string;
};

// Sorted fc codes, order: danger, notTested, unknown, arrows, okay
const customOrderedFC = [1, 2, 10, 11, 999, 997, 998, 8, 4, 6, 7, 3, 9, 5, 12];

export const GroupsTable = observer(
    ({ group, open, onToggleOpen, onClickPrint, showButtons = true, "data-id": dataId }: IProps) => {
        const nameWidth = "40%";

        const [order, setOrder] = React.useState<"asc" | "desc">("asc");
        const [sortColumn, setSortColumn] = React.useState<ISortColumn>("name");

        const handleClickSortBy = (column: ISortColumn) => () => {
            const isAsc = sortColumn === column && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setSortColumn(column);
        };

        const customIteratee = (substance: ISubstance) => {
            if (sortColumn === "name") {
                return getTranslation(substance.id).toLowerCase();
            }

            if (sortColumn === "fc") {
                return customOrderedFC.indexOf(substance.single.fc);
            }
        };

        return (
            <Table data-id={dataId} style={{ marginBottom: open ? 16 : 0 }}>
                <TableHead>
                    <TableRow>
                        <TableCell sortDirection={sortColumn === "name" ? order : false} style={{ width: nameWidth }}>
                            <TableSortLabel
                                active={sortColumn === "name" && open}
                                direction={sortColumn === "name" ? order : "asc"}
                                onClick={open ? handleClickSortBy("name") : undefined}
                            >
                                {getTranslation(group.name).toUpperCase()}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === "fc" && open}
                                direction={sortColumn === "fc" ? order : "asc"}
                                onClick={open ? handleClickSortBy("fc") : undefined}
                            >
                                {open && t("table.heading.single_medication")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: 32 }}>
                            {showButtons && onClickPrint && <PrintIcon onClick={() => onClickPrint(group)} />}
                        </TableCell>
                        <TableCell style={{ width: 32 }}>
                            {showButtons && (
                                <Icon
                                    name={open ? "chevronUp" : "chevronDown"}
                                    color={Colors.primary}
                                    hoverColor={Colors.secondary}
                                    data-id="collapse_toggle"
                                    onClick={() => {
                                        if (onToggleOpen) {
                                            onToggleOpen(group);
                                        }
                                    }}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {open && (
                    <TableBody>
                        {orderBy(uniqBy(group.substances, "id"), customIteratee, order).map(substance => (
                            <TableRow key={substance.id}>
                                <TableCell style={{ width: nameWidth }}>{getTranslation(substance.id)}</TableCell>
                                <TableCell>
                                    <BadgeCollection>
                                        <Badge value={substance.single.fc} />
                                        {substance.single.warnTL && (
                                            <Badge
                                                value="warning"
                                                color="gray"
                                                tooltipText={t("optimizer.legend.section.warning.badge.text")}
                                            />
                                        )}
                                    </BadgeCollection>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </Table>
        );
    },
);
