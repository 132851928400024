import MomentUtils from "@date-io/moment";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import "moment/locale/de";
import * as React from "react";
import { FormattedMessage, RawIntlProvider } from "react-intl";
import { AppRouter } from "./components/routers/AppRouter";
import { theme } from "./components/util/Theme";
import { intl, setLocale, t } from "./i18n/util";
import { authStore } from "./stores/AuthStore";
import { generalStore } from "./stores/GeneralStore";
import { optimizerStore } from "./stores/OptimizerStore";
import { doctorStore } from "./stores/DoctorStore";
import styled from "styled-components";
import { Colors } from "./components/util/Colors";

const ErrorScreen = styled.div`
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 0, 0, 0.1);
    color: ${Colors.danger};
    flex-grow: 1;
    flex-direction: column;
`;

const ErrorScreenTitle = styled.div`
    font-size: 24px;
`;

const ErrorScreenMessage = styled.div`
    font-size: 16px;
`;

const ErrorLink = styled.a`
    color: inherit;
`;

class LocalizedUtils extends MomentUtils {
    getDatePickerHeaderText(date: Moment) {
        return moment(date)
            .locale(generalStore.locale)
            .format("ll");
    }
}

const App: React.FunctionComponent = observer(() => {
    const isRehydrated = authStore.isRehydrated && optimizerStore.isRehydrated && doctorStore.isRehydrated;
    const isAuthenticated = authStore.isAuthenticated;

    if (authStore.authProfile) {
        setLocale(authStore.authProfile.language);
    }

    const needsSubstances = authStore.isDoctor;

    const errorLink = (
        <ErrorLink href="https://www.pharmgenetix.com/kontakt/" rel="noopener noreferrer" target="_blank">
            t('common.here')
        </ErrorLink>
    );

    React.useEffect(() => {
        const loadSubstances = async () => {
            if (isRehydrated && isAuthenticated && needsSubstances) {
                await optimizerStore.loadSubstances();
            }
        };

        loadSubstances();
    }, [isRehydrated, isAuthenticated, needsSubstances]);

    if (!isRehydrated || (needsSubstances && !optimizerStore.isInitialized && !optimizerStore.substances)) {
        // waiting for substances to get loaded
        return null;
    }

    if (isRehydrated && needsSubstances && optimizerStore.isInitialized && !optimizerStore.substances) {
        // Was not able to load substances
        return (
            <RawIntlProvider value={intl} key={generalStore.locale}>
                <ErrorScreen>
                    <ErrorScreenTitle>
                        <b>{t("error.loadSubstanceData")}</b>
                    </ErrorScreenTitle>
                    <br />
                    <br />
                    <ErrorScreenMessage>
                        <FormattedMessage id="error.errorScreenMessage" values={{ errorLink }} />
                    </ErrorScreenMessage>
                </ErrorScreen>
            </RawIntlProvider>
        );
    }

    return (
        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={generalStore.locale}>
            <MuiThemeProvider theme={theme}>
                <RawIntlProvider value={intl} key={generalStore.locale}>
                    <AppRouter />
                </RawIntlProvider>
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    );
});

export default App;
