import { Button, withStyles } from "@material-ui/core";
import { flatMap, uniq } from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { Link, RouteChildrenProps } from "react-router-dom";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { LOCALES } from "../../../../i18n/ILocales";
import { API, isUnauthorizedError } from "../../../../network/API";
import { doctorStore } from "../../../../stores/DoctorStore";
import { generalStore } from "../../../../stores/GeneralStore";
import { optimizerStore } from "../../../../stores/OptimizerStore";
import { IAnalysisHistoryEntry, IAtcCode, IMedication, ISubstance, ISubstanceId } from "../../../../types";
import { StandardContainer } from "../../../containers/StandardContainer";
import { StepDialog } from "../../../dialogs/StepDialog";
import { getGroupSelectionFormPages, IGroupSelectonValues } from "../../../forms/GroupSelectionForm";
import { history } from "../../../routers/history";
import { Routes } from "../../../routers/Routes";
import { AnalysisHistoryDialog } from "../../../ui/AnalysisHistoryDialog";
import { SummaryDialog } from "../../../ui/SummaryDialog";
import { InfoContainer } from "../../../ui/InfoContainer";
import { Legend } from "../../../ui/Legend";
import { SubstanceAutocompleteSearchbar } from "../../../ui/SubstanceAutocompleteSearchbar";
import { UserNavigation } from "../../../ui/UserNavigation";
import { Colors } from "../../../util/Colors";
import { Icon } from "../../../util/Icon";
import { getAtcGroup } from "../../../util/Optimizer";
import { InteractionsTable } from "./InteractionsTable";
import { NoMedicationHint } from "./NoMedicationHint";

const StyledButton = withStyles({
    root: {
        height: 56,
    },
})(Button);

const ResetButton = withStyles({
    root: {
        letterSpacing: 1,
        marginRight: 24,
        "@media (max-width:1190px)": { marginTop: 24 },
    },
})(Button);

const NavigationHeading = styled.h4`
    margin-left: 32px;
`;

const BackContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Container = styled(StandardContainer)`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding-bottom: 106px;
`;

const Justify = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 40px;
`;

const Remarks = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #878787;
    margin-top: 16px;
    & > * + * {
        margin-left: 8px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    flex-flow: row;

    @media only screen and (max-width: 1190px) {
        flex-flow: column-reverse;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-flow: column;
`;

const Headline = styled.h3`
    margin-bottom: 24px;
`;

const SummarySearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const SummaryButton = withStyles({
    root: {
        marginLeft: "16px",
    },
})(Button);

const getGroupsForSubstance = (substanceId: ISubstanceId) => {
    const possibleAtcCodes = optimizerStore.substances?.atcCodes[substanceId] || [];

    return uniq(
        possibleAtcCodes.map(atcCode => {
            const group = getAtcGroup(
                optimizerStore.substances?.atcTree || { atcCode: "", name: "" },
                atcCode,
                "group",
            );

            return group;
        }),
    );
};

const EMPTY_MEDICATION = { medication: [] } as IMedication;

type IParams = {
    uid?: string;
};

type IProps = RouteChildrenProps<IParams>;

export const MedicationAnalysisSite = observer(({ match }: IProps) => {
    const uid = match?.params.uid;

    const [isLoading, setIsLoading] = React.useState(true);
    // lastSavedMedication is used to track if a patient user can save or not
    const [lastSavedMedication, setLastSavedMedication] = React.useState<IMedication>(EMPTY_MEDICATION);
    const [currentMedication, setCurrentMedication] = React.useState<IMedication>(EMPTY_MEDICATION); // TODO move to doctorstore?
    const [remarks, setRemarks] = React.useState("");
    const [selectedSubstanceIds, setSelectedSubstanceIds] = React.useState<string[]>([]);
    const [nextStep, setNextStep] = React.useState<"manualReplace" | "autoReplace" | null>(null);
    const [isAnalysisHistoryOpen, setIsAnalysisHistoryOpen] = React.useState<boolean>(false);
    const [isSummaryDialogOpen, setIsSummaryDialogOpen] = React.useState<boolean>(false);
    const [medicationHistory, setMedicationHistory] = React.useState<IAnalysisHistoryEntry[]>();
    const [selectedHistoryEntry, setSelectedHistoryEntry] = React.useState<IAnalysisHistoryEntry>();

    React.useEffect(() => {
        const loadData = async () => {
            generalStore.isLoading = true;

            try {
                if (uid) {
                    if ((!doctorStore.selectedPatient || doctorStore.selectedPatient?.uid !== uid) && uid) {
                        await doctorStore.loadPatient(uid);
                    }

                    if (!doctorStore.selectedPatient?.canOptimize) {
                        history.push(Routes.DOCTOR.ROOT);
                    } else {
                        const savedMedication = await API.getMedication(uid);

                        if (savedMedication.substancesVersion !== optimizerStore.substances?.version) {
                            await optimizerStore.loadSubstances(savedMedication.substancesVersion);
                        }

                        const medicationHistory = await API.getMedicationHistory(uid);
                        setMedicationHistory(medicationHistory.results);
                        setRemarks(savedMedication.remarks);

                        setCurrentMedication(savedMedication.calculateResult?.combinations[0] ?? EMPTY_MEDICATION);
                        setLastSavedMedication(savedMedication.calculateResult?.combinations[0] ?? EMPTY_MEDICATION);
                    }
                }
            } catch (error) {
                if (!isUnauthorizedError(error)) {
                    generalStore.errorMessage = t("error.loadMedication");
                }
                console.error(error);
            }

            generalStore.isLoading = false;
            setIsLoading(false);
        };

        // Resest values in case user goes back in history or clicks the back button from either AutoOptimizeSite or ManualOptimizeSite
        doctorStore.currentMedication = null;
        doctorStore.optimizerResultSameLevel = [];
        doctorStore.manualReplaceSubstance = null;
        doctorStore.selectedGroups = {};

        loadData();
    }, [uid]);

    const handleSelectSubstance = async (substanceId: ISubstanceId, tradeName?: string) => {
        if (!uid) {
            return;
        }

        generalStore.isLoading = true;

        try {
            const tradeNameSubstanceIds = Object.keys(optimizerStore.substances?.tradeNames || {});
            const currentMeds: IMedication = currentMedication ? currentMedication : { medication: [] };

            const substanceIds = flatMap(tradeNameSubstanceIds, tradeNameSubstanceId => {
                const tradeNames = optimizerStore.substances?.tradeNames[tradeNameSubstanceId] || [];
                const isSubstanceSelected = currentMeds.medication.find(
                    substance => substance.id === tradeNameSubstanceId,
                );

                return tradeName && tradeNames.includes(tradeName) && !isSubstanceSelected
                    ? [tradeNameSubstanceId]
                    : [];
            });

            const newSubstances = [
                ...currentMeds.medication.map(substance => substance.id),
                ...(tradeName ? substanceIds : [substanceId]),
            ];

            const result = await API.saveMedication(uid, newSubstances, "");
            setCurrentMedication(result.calculateResult?.combinations[0] ?? EMPTY_MEDICATION);
            setRemarks(result.remarks);
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.saveMedication");
            }
            console.error(error);
        }

        generalStore.isLoading = false;
    };

    const handleClickRemoveMedication = async (atcCode: IAtcCode) => {
        if (!uid) {
            return;
        }

        generalStore.isLoading = true;

        try {
            const newSubstances = currentMedication.medication
                .map(substance => substance.id)
                .filter(id => id !== atcCode);

            const result = await API.saveMedication(uid, newSubstances, "");
            setCurrentMedication(result.calculateResult?.combinations[0] ?? EMPTY_MEDICATION);
            setRemarks(result.remarks);
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.saveMedication");
            }
            console.error(error);
        }

        generalStore.isLoading = false;
    };

    const handleToggleFixSubstance = (substanceId: ISubstanceId) => {
        const isFixed = doctorStore.fixedSubstances.includes(substanceId); // TODO move handling of adding and removing fixed substances into store as action

        if (isFixed) {
            doctorStore.fixedSubstances = doctorStore.fixedSubstances.filter(id => id !== substanceId);
        } else {
            doctorStore.fixedSubstances = [...doctorStore.fixedSubstances, substanceId];
        }
    };

    const handleClickOptimizeAutomatically = () => {
        if (uid) {
            doctorStore.currentMedication = currentMedication;

            const substancesWithMultipleGroups = flatMap(currentMedication.medication, substance =>
                getGroupsForSubstance(substance.id).length > 1 && !doctorStore.fixedSubstances.includes(substance.id)
                    ? [substance.id]
                    : [],
            );

            if (substancesWithMultipleGroups.length > 0) {
                setNextStep("autoReplace");
                setSelectedSubstanceIds(substancesWithMultipleGroups);
            } else {
                history.push(Routes.DOCTOR.OPTIMIZE_AUTO.replace(":uid", uid));
            }
        }
    };

    const handleClickReplaceManually = (substance: ISubstance) => {
        if (uid && substance.id) {
            doctorStore.currentMedication = currentMedication;
            doctorStore.manualReplaceSubstance = substance;

            const groups = getGroupsForSubstance(substance.id);

            if (groups.length > 1) {
                setNextStep("manualReplace");
                setSelectedSubstanceIds([substance.id]);
            } else {
                history.push(
                    Routes.DOCTOR.OPTIMIZE_MANUAL.replace(":uid", uid),
                );
            }
        }
    };

    const handleSubmitGroupSelectionDialog = (selectedGroups: IGroupSelectonValues) => {
        doctorStore.selectedGroups = selectedGroups;

        if (uid) {
            if (nextStep === "manualReplace") {
                setSelectedSubstanceIds([]);
                setNextStep(null);

                history.push(
                    Routes.DOCTOR.OPTIMIZE_MANUAL.replace(":uid", uid),
                );
            } else if (nextStep === "autoReplace") {
                setSelectedSubstanceIds([]);
                setNextStep(null);

                history.push(Routes.DOCTOR.OPTIMIZE_AUTO.replace(":uid", uid));
            }
        }
    };

    const handleCloseGroupSelectionDialog = () => {
        setSelectedSubstanceIds([]);
        setNextStep(null);
    };

    const selectGroupsInitialValues = selectedSubstanceIds.reduce(
        (accumulator, substanceId) => ({
            ...accumulator,
            [substanceId]: [],
        }),
        {},
    );

    const selectGroupsForm = getGroupSelectionFormPages(selectGroupsInitialValues, nextStep === "autoReplace");

    if (isLoading) {
        return null;
    }

    const handleOpenAnalysisHistoryDialog = () => {
        setIsAnalysisHistoryOpen(true);
    };

    const handleCloseAnalysisHistoryDialog = () => {
        setIsAnalysisHistoryOpen(false);
    };

    const handleAnalysisHistoryConfirm = async (entry: IAnalysisHistoryEntry) => {
        setIsAnalysisHistoryOpen(false);

        if (uid) {
            try {
                const calculateResponse = await API.calculate(uid, entry.substances, true);
                setCurrentMedication(calculateResponse.combinations[0]);
                setRemarks(entry.remarks);
                setSelectedHistoryEntry(entry);
            } catch (error) {
                if (!isUnauthorizedError(error)) {
                    generalStore.errorMessage = t("error.loadMedication");
                }
                console.error(error);
            }
        }
    };

    const handleClickSaveMedication = async () => {
        if (!uid) {
            return;
        }

        try {
            const substances = currentMedication.medication.map(substance => substance.id);

            const result = await API.saveMedication(uid, substances, "");
            setCurrentMedication(result.calculateResult?.combinations[0] ?? EMPTY_MEDICATION);
            setLastSavedMedication(result.calculateResult?.combinations[0] ?? EMPTY_MEDICATION);
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.saveMedication");
            }
        }
    };

    const substanceSearchbar = (
        <SubstanceAutocompleteSearchbar
            placeholder={t("screen.doctor.optimize.actions.searchbar.placeholder")}
            data-id="active_agent_search"
            substances={flatMap(Object.keys(optimizerStore.substances?.atcCodes || {}), substanceId =>
                currentMedication && currentMedication.medication.find(substance => substance.id === substanceId)
                    ? []
                    : [substanceId],
            )}
            onSelect={handleSelectSubstance}
            resetOnSelect
            style={{ marginBottom: 16, width: "100%" }}
        />
    );

    return (
        <>
            {medicationHistory && (
                <AnalysisHistoryDialog
                    entries={medicationHistory}
                    isOpen={isAnalysisHistoryOpen}
                    onClose={handleCloseAnalysisHistoryDialog}
                    onConfirm={handleAnalysisHistoryConfirm}
                />
            )}
            <UserNavigation
                leftComponent={
                    <BackContainer>
                        <Link to={Routes.DOCTOR.ROOT}>
                            <Icon name="arrowLeft" hoverColor={Colors.secondary} />
                        </Link>
                        <NavigationHeading>
                            {doctorStore.selectedPatient?.lastname} {doctorStore.selectedPatient?.firstname}
                        </NavigationHeading>
                    </BackContainer>
                }
            />

            {uid && generalStore.locale === LOCALES.de ? (
                <SummaryDialog
                    isOpen={isSummaryDialogOpen}
                    uid={uid}
                    onClose={() => setIsSummaryDialogOpen(false)}
                    currentMedication={currentMedication}
                />
            ) : null}
            <Container>
                <Content>
                    <Justify>
                        <div>
                            <TextContainer>
                                <Headline>{t("screen.doctor.optimize.heading")}</Headline>
                                <p>{t("screen.doctor.optimize.sub_text")}</p>
                            </TextContainer>
                        </div>
                        <ButtonContainer>
                            <ResetButton size="small" onClick={handleOpenAnalysisHistoryDialog}>
                                {t("screen.doctor.optimize.actions.analysisHistory")}
                            </ResetButton>
                            <StyledButton
                                variant="contained"
                                color="primary"
                                onClick={handleClickOptimizeAutomatically}
                                disabled={!currentMedication || !currentMedication.medication.length}
                            >
                                {t("screen.doctor.optimize.optimize_medicaction")}
                            </StyledButton>
                        </ButtonContainer>
                    </Justify>
                    {selectedHistoryEntry && (
                        <InfoContainer
                            headline={t("screen.doctor.optimize.history_information.headline", {
                                date: moment(selectedHistoryEntry.createdAt).format("DD.MM.YYYY"),
                                doctorName: `${
                                    selectedHistoryEntry.doctor.firstname ? selectedHistoryEntry.doctor.firstname : ""
                                } ${selectedHistoryEntry.doctor.lastname ? selectedHistoryEntry.doctor.lastname : "-"}`,
                            })}
                            text={t("history.info.text")}
                        />
                    )}
                    {uid && generalStore.locale === LOCALES.de ? (
                        <SummarySearchContainer>
                            {substanceSearchbar}
                            <SummaryButton
                                onClick={() => setIsSummaryDialogOpen(true)}
                                disabled={!currentMedication || currentMedication.medication.length === 0}
                            >
                                {t("screen.doctor.optimize.getSummary")}
                            </SummaryButton>
                        </SummarySearchContainer>
                    ) : (
                        substanceSearchbar
                    )}

                    {!currentMedication || currentMedication.medication.length === 0 ? (
                        <NoMedicationHint />
                    ) : (
                        <>
                            <InteractionsTable
                                data={currentMedication}
                                fixed={doctorStore.fixedSubstances}
                                onToggleFixSubstance={handleToggleFixSubstance}
                                onClickRemoveMedication={handleClickRemoveMedication}
                                onClickReplaceManually={handleClickReplaceManually}
                                fixable
                            />
                            {remarks && (
                                // TODO always show under table?
                                <Remarks>
                                    <Icon name="comment" />
                                    <span>{remarks}</span>
                                </Remarks>
                            )}
                        </>
                    )}
                </Content>
                <Legend />
                {selectedSubstanceIds.length > 0 && (
                    <StepDialog
                        pages={selectGroupsForm.pages}
                        open
                        initialValues={selectGroupsForm.initialValues}
                        onClose={handleCloseGroupSelectionDialog}
                        onSubmit={handleSubmitGroupSelectionDialog}
                        submitButtonTextId={"dialog.button_confirm"}
                    />
                )}
            </Container>
        </>
    );
});
